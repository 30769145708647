<template>
  <div class="main">
    <div class="container">
      <div class="divVideos">
        <v-row>
          <v-col style="margin-top: 100px" cols="12" class="text-center"
            ><span>CONOCE NUESTRAS INVESTING EXPERIENCE AQUI</span></v-col
          >
          <v-col class="card card3" cols="12" sm="6" md="6">
            <div @click="fnPlay('myVideo', 'action_audio1')" class="box-mov">
              <div class="mov-des">
                <div id="hover" class="des-text" v-if="getAudio1">
                  <h3
                    @mouseover="fnTexth3"
                    class="textH3 text-center"
                    style="z-index: 1"
                  >
                    Activa tu sentido verde <br />
                    aquí
                  </h3>
                </div>
              </div>
              <div class="mov-item">
                <video
                  id="myVideo"
                  autoplay
                  :muted="getAudio1"
                  width="90%"
                  loop
                >
                  <source src="../assets/experiencia.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <v-btn fab class="btn_sound" icon x-large
                  ><v-icon
                    v-if="volume"
                    class="icon_sound"
                    color="#85af61"
                    x-large
                    >mdi-volume-high</v-icon
                  ></v-btn
                >
                <v-btn v-if="!volume" fab class="btn_sound" icon x-large
                  ><v-icon class="icon_sound" color="#85af61" x-large
                    >mdi-volume-off</v-icon
                  ></v-btn
                >
                <canvas id="cnv"></canvas>
              </div></div
          ></v-col>

          <v-col class="card card4" cols="12" sm="6" md="6">
            <div @click="fnPlay('myVideo2', 'action_audio2')" class="box-mov">
              <div class="mov-des">
                <div id="hover2" class="des-text" v-if="getAudio2">
                  <h3
                    @mouseover="fnTexth3"
                    class="textH3 text-center"
                    style="z-index: 1"
                  >
                    Activa tu sentido verde <br />
                    aquí
                  </h3>
                </div>
              </div>
              <div class="mov-item">
                <video
                  id="myVideo2"
                  autoplay
                  :muted="getAudio2"
                  width="90%"
                  loop
                >
                  <source src="../assets/altar.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <v-btn fab class="btn_sound" icon x-large
                  ><v-icon
                    v-if="volume"
                    class="icon_sound"
                    color="#85af61"
                    x-large
                    >mdi-volume-high</v-icon
                  ></v-btn
                >
                <v-btn v-if="!volume" fab class="btn_sound" icon x-large
                  ><v-icon class="icon_sound" color="#85af61" x-large
                    >mdi-volume-off</v-icon
                  ></v-btn
                >
                <canvas id="cnv2"></canvas>
              </div></div
          ></v-col>
          <v-col xs="12" sm="6" md="6" lg="6" xl="6" class="card5">
            <div @click="fnPlay('myVideo3', 'action_audio3')" class="box-mov">
              <div class="mov-des">
                <div id="hover3" class="des-text" v-if="getAudio3">
                  <h3
                    @mouseover="fnTexth3"
                    class="textH3 text-center"
                    style="z-index: 1"
                  >
                    Activa tu sentido verde <br />
                    aquí
                  </h3>
                </div>
              </div>
              <div class="mov-item">
                <video
                  id="myVideo3"
                  autoplay
                  :muted="getAudio3"
                  width="90%"
                  loop
                >
                  <source src="../assets/experiencia2.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <v-btn fab class="btn_sound" icon x-large
                  ><v-icon
                    v-if="volume"
                    class="icon_sound"
                    color="#85af61"
                    x-large
                    >mdi-volume-high</v-icon
                  ></v-btn
                >
                <v-btn v-if="!volume" fab class="btn_sound" icon x-large
                  ><v-icon class="icon_sound" color="#85af61" x-large
                    >mdi-volume-off</v-icon
                  ></v-btn
                >
                <canvas id="cnv3"></canvas>
              </div></div
          ></v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    col: true,
    prev: false,
    volume: true,
  }),
  methods: {
    fnCanvas(video_id, canvas_id) {
      let vid = document.getElementById(video_id);
      let canvas = document.getElementById(canvas_id);
      let ctx = canvas.getContext("2d");
      // damos al canvas la misma altura y anchura que al video
      let cw = (canvas.width = 1575.89);
      let ch = (canvas.height = 888);
      ctx.drawImage(vid, 0, 0, cw, ch);

      vid.addEventListener(
        "play", //si el video se esta reproduciendo
        () => {
          // dibujelo en el canvas
          pintarVideo(vid, ctx, cw, ch);
        },
        false
      );

      function pintarVideo() {
        requestAnimationFrame(pintarVideo);
        // si el video esta parado o se ha acabado no hagas nada y sal de aquí
        if (vid.paused || vid.ended) {
          return;
        }
        // pinta el video en el canvas
        ctx.drawImage(vid, 0, 0, cw, ch);
      }
    },
    fnTexth3() {
      TweenMax.to(".textH3", 0.4, { scale: 1.2, ease: Bounce.easeOut });
      TweenMax.to(".textH3", 0.2, { scale: 1, delay: 0.4 });
    },
    fnPlay(id_video, action) {
      var video = document.getElementById(id_video);

      if (video.muted) {
        this.$store.dispatch(action, false);
      } else {
        this.$store.dispatch(action, true);
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.fnCanvas("myVideo", "cnv");
    this.fnCanvas("myVideo2", "cnv2");
    this.fnCanvas("myVideo3", "cnv3");
  },
  computed: {
    getAudio1: {
      get() {
        return this.$store.getters["getter_audio1"];
      },
      set() {},
    },
    getAudio2: {
      get() {
        return this.$store.getters["getter_audio2"];
      },
      set() {},
    },
    getAudio3: {
      get() {
        return this.$store.getters["getter_audio3"];
      },
      set() {},
    },
  },
};
</script>
<style scoped>
video {
  border-radius: 10px;
  /* width: 90%; */
}
/* .col1,
.col2,
.col3 {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.divVideos {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* margin-top: 5%; */
}
.col3 {
  /* margin-bottom: 10%; */
}
.vid_span {
}
span {
  font-size: 3em;
  color: #85af61;
}
.container {
  /* margin-top: 5%; */
  /* height: 100vh; */
}
.box-mov {
  position: relative;
}
.box-mov .mov-des {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.5s ease;
  opacity: 1;
}
.box-mov .mov-des .des-text {
  background-color: rgba(0, 0, 0, 0.493);
  padding: 1rem;
  color: #ffffff;
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  bottom: 16.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5%; */
  border-radius: 10px;
}
.box-mov .mov-des .des-text p {
  margin: 0;
  text-transform: uppercase;
}
.box-mov .mov-des img {
  height: 100%;
}
.box-mov .mov-des.hide {
  opacity: 0;
  visibility: hidden;
}
.mov-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media screen and (min-width: 1904px) {
  .main {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    margin-bottom: 400px;
  }
} */
#cnv,
#cnv2,
#cnv3 {
  border-radius: 10px;
  width: 90%;
  margin-bottom: 10%;
  background: url(../assets/prev_videos.png),
    linear-gradient(110deg, black 0%, #000000 100%);
  background-size: cover;
  background-repeat: no-repeat;
}
video {
  position: absolute;
  width: 0;
  height: 0;
}
.btn_sound {
  position: absolute;
  top: 1.5%;
  left: 7%;
  z-index: 10;
}
@media screen and (min-width: 1904px) and (min-height: 1px) {
  .main {
    width: 100%;
    height: 1600px;
    background-color: rgb(255, 255, 255);
  }
}
/* @media screen and (min-width: 1904px) and (min-height: 800px) and (max-height: 900px) {
  .main {
    width: 100%;
    height: 140vh;
    background-color: rgb(255, 255, 255);
  }
}
@media screen and (min-width: 1904px) and (min-height: 700px) and (max-height: 800px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 800px;
  }
}
@media screen and (min-width: 1904px) and (min-height: 600px) and (max-height: 700px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 900px;
  }
}
@media screen and (min-width: 1904px) and (min-height: 500px) and (max-height: 600px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 900px;
  }
}
@media screen and (min-width: 1904px) and (max-height: 500px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 900px;
  }
} */

@media screen and (min-width: 1264px) and (max-width: 1904px) and (min-height: 10px) {
  .main {
    width: 100%;
    height: 1250px;
    background-color: rgb(255, 255, 255);
  }
}
/* @media screen and (min-width: 1264px) and (max-width: 1904px) and (min-height: 800px) and (max-height: 900px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 350px;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) and (min-height: 700px) and (max-height: 800px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 550px;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) and (min-height: 600px) and (max-height: 700px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 500px;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) and (min-height: 500px) and (max-height: 600px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 650px;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1904px) and (max-height: 500px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 700px;
  }
} */

@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 10px) {
  .main {
    width: 100%;
    height: 1250px;
    background-color: rgb(255, 255, 255);
  }
  .textH3 {
    font-size: 0.9em;
  }
}
/* @media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 800px) and (max-height: 900px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 300px;
  }
  .textH3 {
    font-size: 0.9em;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 700px) and (max-height: 800px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 450px;
  }
  .textH3 {
    font-size: 0.9em;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 600px) and (max-height: 700px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 500px;
  }
  .textH3 {
    font-size: 0.9em;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (min-height: 500px) and (max-height: 600px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 600px;
  }
  .textH3 {
    font-size: 0.9em;
  }
}
@media screen and (min-width: 960px) and (max-width: 1264px) and (max-height: 500px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 600px;
  }
  .textH3 {
    font-size: 0.9em;
  }
} */
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 600px) {
  .main {
    width: 100%;
    height: 1100px;
    background-color: rgb(255, 255, 255);
  }
  .textH3 {
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 10px) and (max-height: 600px) {
  .main {
    width: 100%;
    height: 1000px;
    background-color: rgb(255, 255, 255);
  }
  .textH3 {
    font-size: 0.7em;
  }
}
/* @media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 800px) and (max-height: 900px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 150px;
  }
  .textH3 {
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 700px) and (max-height: 800px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 300px;
  }
  .textH3 {
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 600px) and (max-height: 700px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 350px;
  }
  .textH3 {
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (min-height: 500px) and (max-height: 600px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 400px;
  }
  .textH3 {
    font-size: 0.7em;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) and (max-height: 500px) {
  .main {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 430px;
  }
  .textH3 {
    font-size: 0.7em;
  }
} */

@media screen and (max-width: 600px) and (min-height: 1px) {
  .container {
    /* margin-top: 10%; */
  }
  span {
    font-size: 1.3em;
    color: #85af61;
  }
  .main {
    width: 100%;
    /* height: 100%; */
    background-color: rgb(255, 255, 255);
    /* margin-bottom: 40vh; */
    height: 1550px;
  }
  .textH3 {
    font-size: 0.6em;
  }
  /* @media screen and (min-height: 1100px) {
    .main {
      width: 100%;
      height: 100vh;
      background-color: rgb(255, 255, 255);
    }
  } */
}

h3,
video,
canvas {
  cursor: pointer;
}
.btn_sound {
  z-index: 1;
}
h3 {
  color: #85af61;
  text-shadow: 1px 1px white;
}
</style>